import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

// css packages
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/logo.css";

// layouts
import Profile from "views/Profile.js";
import Calendar from "views/Calendar.js";
import Testimonials from "views/Testimonials.js";
import Gallery from "views/Gallery.js";

const root = createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      {/* add routes without layouts */}
      <Route path="/calendar" exact element={<Calendar />} />
      <Route path="/testimonials" exact element={<Testimonials />} />
      <Route path="/gallery" exact element={<Gallery />} />
      <Route path="/" exact element={<Profile />} />

      {/* add redirect for first page */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </Router>
);
