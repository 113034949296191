// import React from "react";
import React from 'react';

import ResponsiveHTMLSpan from 'components/ResponsiveHTMLSpan.js';
import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

import avatar from "assets/img/avatar.jpg";
import avatar2 from "assets/img/avatar-illustrated.png";

export default function Profile() {
  return (
    <>
      <Navbar transparent />
      {/* <main className="profile-page z-0 -m-[8rem] md:-m-20 lg:m-0"> */}
      <main className="profile-page z-0 shadow-xl border-b border-teal-500">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-cover bg-zinc-950"
            style={{
              background: '#000000',
              backgroundImage: 
              'url("https://images.unsplash.com/photo-1495435229349-e86db7bfa013?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3354&q=80")',
              backgroundPosition: 'center',  // Add this line for positioning if needed
              backgroundRepeat: 'no-repeat', // Add this line for repetition if needed
              backgroundSize: 'cover',  
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-30 bg-black"
            ></span>
          </div>
        </section>
        <section className="relative py-16 bg-white dark:bg-zinc-900">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white dark:bg-black dark:text-white w-full mb-6 shadow-xl rounded-lg -mt-[24rem] sm:-mt-[22rem] md:-mt-[20rem] lg:-mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={avatar}
                        onMouseOver={e => (e.currentTarget.src = avatar2)}
                        onMouseOut={e => (e.currentTarget.src = avatar)}
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center pt-24 lg:pt-0">
                    <div className="flex justify-center lg:justify-end lg:pt-0">
                      <div className="py-4 px-3">
                        <a href="http://maps.apple.com/?q=1180+W+National+Rd,Vandalia,OH">
                          <button
                              className="bg-sky-500 hover:bg-sky-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                              type="button"
                          >
                            Directions
                          </button>
                        </a>
                        <a href="sms:+19378307925">
                          <button
                              className="bg-green-500 hover:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                              type="button"
                          >
                            937-830-7925
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    <div className="flex justify-center py-0 md:py-4 bg-slate-100 dark:bg-cyan-950/30  
                    border-t-white/50 
                    border-r-slate-200/50 
                    border-b-slate-200/75 
                    border-l-white/25
                    dark:border-t-cyan-200/50 
                    dark:border-r-cyan-200/25 
                    dark:border-b-cyan-200/20 
                    dark:border-l-cyan-200/30
                    border-[1px]
                    lg:border-none
                    lg:bg-inherit rounded-md lg:rounded-tr-none lg:rounded-tl-none">
                      <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-slate-800 dark:text-slate-200">
                          1,500+
                        </span>
                        <span className="text-sm text-slate-600">
                          Sales
                        </span>
                      </div>
                      <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-slate-800 dark:text-slate-200">
                          9
                        </span>
                        <span className="text-sm text-slate-600">
                          Years
                        </span>
                      </div>
                      <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-slate-800 dark:text-slate-200">
                          7
                        </span>
                        <span className="text-sm text-slate-600">
                          Awards
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center mt-5 lg:mt-12">

                  <div className="md:my-10 lg:my-12">
                    <h3 className="text-4xl font-accent font-bold leading-normal tracking-wide">
                      Steve Ryherd
                    </h3>
                    <h4>
                      <span>Your <span class="border-b-orange-200 border-b-2 font-bold">trusted</span> sales and leasing consultant</span>
                    </h4>
                  </div>

                  <div className="text-sm leading-normal my-8 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-red-400"></i>
                    Proudly serving Dayton, OH
                  </div>

                  <div className="mb-2 text-slate-600 dark:text-slate-300 text-sm md:text-base">
                    <i className="fas fa-briefcase mr-2 text-lg text-slate-400 dark:text-slate500"></i>
                    <ResponsiveHTMLSpan
                      defaultText="<b>Sales and Leasing Consultant</b><br />Joseph Airport Toyota"
                      longDescription="Sales and Leasing Consultant - Joseph Airport Toyota"
                    />
                  </div>
                  <div className="mb-2 text-slate-600 dark:text-slate-300 text-sm md:text-base">
                    <i className="fas fa-university mr-2 text-lg text-slate-400 dark:text-slate500"></i>
                    <ResponsiveHTMLSpan
                      defaultText="<b>Sinclair Community College</b>, and<br />Miami&nbsp;Valley Career&nbsp;Technology&nbsp;Center"
                      longDescription="Sinclair Community College | Miami Valley Career Technology Center"
                    />
                  </div>
                  <div className="mb-2 text-slate-600 dark:text-slate-300 text-sm md:text-base">
                    <i className="fas fa-award mr-2 text-lg text-slate-400 dark:text-slate500"></i>
                    <ResponsiveHTMLSpan
                      defaultText="<b>Toyota Sales Society</b><br />Gold Level Recognition (7&nbsp;years)"
                      longDescription="Toyota Sales Society - Gold Level Recognition (7&nbsp;years)"
                    />
                  </div>
                  <div className="mb-2 text-slate-600 dark:text-slate-300 text-sm md:text-base">
                    <i className="fas fa-award mr-2 text-lg text-slate-400 dark:text-slate500"></i>
                    
                    <ResponsiveHTMLSpan
                      defaultText="<b>Business Professionals of America</b><br />E-Commerce Design (2&nbsp;years)"
                      longDescription="Business Professionals of America - E-Commerce Design (2&nbsp;years)"
                    />
                  </div>
                </div>
                <div className="mt-16 py-10 border-t border-slate-200 text-start">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <h1 class="text-4xl tracking-wide font-accent py-4 text-center md:text-center font-bold bg-gradient-to-r from-[#f07532]  to-[#27d8d7] dark:from-[#27d8d7] dark:via-[#9ed829] dark:to-[#e27532] bg-clip-text text-transparent">Just a little about me</h1>
                      {/* <h1 class="text-5xl p-4 text-left font-bold bg-gradient-to-r from-purple-600 via-pink-600 to-blue-600 bg-clip-text text-transparent">A little about me</h1> */}
                      <p className="indent md:indent-4 lg:indent-8 first-line:tracking-widest
  sfirst-letter:font-bold mb-4 text-lg xl:text-xl leading-relaxed lg:leading-loose xl:leading-loose text-slate-600 dark:text-slate-300">
                        Originally a <span className="tracking-widest">software developer</span> with a decade of experience, I have always enjoyed finding ways to
                        improve products and experiences (especially through technology).
                        My latest endeavor is to show the automotive sales industry and consumers that there is a better way to buy/sell cars.
                      </p>
                      <p className="mb-4 text-lg xl:text-xl leading-relaxed lg:leading-loose xl:leading-loose  text-slate-600 dark:text-slate-300">
                        If you've been looking for an easier, more enjoyable, and all-around higher quality experience, you've come to the right place.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
