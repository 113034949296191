/*eslint-disable*/
import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import {ReactComponent as Logo} from 'assets/img/logo.svg';


// components
// import PagesDropdown from "components/Dropdowns/PagesDropdown.js";
import Cal, { getCalApi } from "@calcom/embed-react";

export default function Navbar(props) {

  useEffect(()=>{
	  (async function () {
      const cal = await getCalApi();
      cal("ui", {"styles":{"branding":{"brandColor":"#000000"}},"hideEventTypeDetails":true,"layout":"week_view"});
	  })();
	}, [])

  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="top-0 fixed border-0 z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-zinc-950 bg-gradient-to-br from-cyan-950 to-zinc-950 shadow-lg border-b-2 border-teal-500">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <NavLink
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4 pt-2 whitespace-nowrap uppercase"
              to="/"
            >
              <Logo alt="Steve Ryherd" className="h-10 small-logo md:h-16 md:large-logo" />
            </NavLink>

            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="text-white fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "mt-2 lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg group is-open" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none mr-auto">
              <li className="flex items-center hover:group-[.is-open]:bg-blue-100 hover:group-[.is-open]:rounded-t">
                <a
                  className="lg:text-white lg:hover:text-yellow-200 text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://card.steveryherd.com"
                >
                  <i className="lg:text-slate-200 text-slate-400 far fa-file-alt text-lg leading-lg mr-2" />{" "}
                  Business Card
                </a>
              </li>
              <li className="flex items-center hover:group-[.is-open]:bg-blue-100">
                <a
                    className="lg:text-white lg:hover:text-yellow-200 text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    // ahref="https://cal.com/steveryherd/general-consultation?layout=week_view"
                    data-cal-link="steveryherd/general-consultation"
                    data-cal-config='{"layout":"month_view"}'
                >
                  <i className="lg:text-slate-200 text-slate-400 far fa-calendar text-lg leading-lg mr-2" />{" "}
                  Availability
                </a>
              </li>
              <li className="flex items-center hover:group-[.is-open]:bg-blue-100">
                <NavLink
                  to="/gallery"
                  className={({ isActive }) =>
                    `px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold ${isActive ? 
                      'lg:text-yellow-100 lg:hover:text-yellow-200 text-purple-500 group is-active' : 'lg:text-white lg:hover:text-yellow-200 text-slate-700'}`
                  }
                  // exact
                >
                  <i className="lg:text-slate-200 text-slate-400 far fa-images text-lg leading-lg mr-2 group-[.is-active]:text-purple-300" />{" "}
                  Gallery
                </NavLink>
              </li>
            </ul>
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              {/*<li className="flex items-center">*/}
                {/*<PagesDropdown />*/}
              {/*</li>*/}
              <li className="flex items-center hover:group-[.is-open]:bg-blue-100">
                <a
                  className="lg:text-white lg:hover:text-slate-200 hover:bg-blue-800 hover:group-[.is-open]:bg-inherit text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://www.facebook.com/steveryherd/"
                  target="_blank"
                >
                  <i className="lg:text-slate-200 text-slate-400 fab fa-facebook text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">View on Facebook</span>
                </a>
              </li>

              <li className="flex items-center hover:group-[.is-open]:bg-blue-100">
                <a
                  className="lg:text-white lg:hover:text-slate-200 hover:bg-rose-500 hover:group-[.is-open]:bg-inherit text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="http://www.instagram.com/steveryherd"
                  target="_blank"
                >
                  <i className="lg:text-slate-200 text-slate-400 fab fa-instagram text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">View on Instagram</span>
                </a>
              </li>


              <li className="flex flex-wrap items-center justify-center border-t lg:border-none p-4 lg:p-0">

                <a href="tel:+19378307925">
                  <button
                      className="bg-white text-slate-700 active:bg-slate-50 hover:bg-green-300 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mb-0 mr-3 lg:mr-1 lg:mb-0 lg:ml-3 ease-linear transition-all duration-150"
                      type="button"
                  >
                    <i className="fas fa-phone-alt"></i><span className="ml-2">Call&nbsp;Me</span>
                  </button>
                </a>

                <a href="sms:+19378307925">
                <button
                    className="bg-white text-slate-700 active:bg-slate-50 hover:bg-green-300 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mb-0 ml-3 lg:mr-1 lg:mb-0 lg:ml-3 ease-linear transition-all duration-150"
                    type="button"
                >
                  <i className="fas fa-mobile-alt"></i><span className="ml-2">Text&nbsp;Me</span>
                </button>
                </a>

              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
