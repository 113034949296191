import React, { useState, useEffect } from 'react';
// import './YourComponent.css'; // Import your component-specific CSS file

const ResponsiveHTMLSpan = ({ defaultText, longDescription }) => {
  const [shouldDisplayLongText, setShouldDisplayLongText] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      // Adjust the breakpoint value based on your design
      const breakpoint = 600; // For example, consider screens smaller than 600px as mobile

      // Check if the screen width is less than the breakpoint
      const isMobile = window.innerWidth < breakpoint;

      // Update the state based on the screen width
      setShouldDisplayLongText(!isMobile);
    };

    // Run the check on initial mount and resize
    checkScreenWidth();

    // Add event listener for window resize
    window.addEventListener('resize', checkScreenWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  return (
    <span className="responsive-html-span-container">
      {shouldDisplayLongText ? (
        <span dangerouslySetInnerHTML={{ __html: longDescription }} />
      ) : (
        <span dangerouslySetInnerHTML={{ __html: defaultText }} />
      )}
    </span>
  );
};

export default ResponsiveHTMLSpan;
