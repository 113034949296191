import React from "react";

import open_to_all from "assets/img/open_to_all-2x-tm.png"

export default function Footer() {
  return (
    <>
      <footer className="bg-slate-200 dark:bg-[#0b2531] pt-8 pb-6 shadow-inner">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-5/12 px-4">
              <h4 className="text-3xl font-semibold text-[#f2714b] dark:text-[#ffbc53]">Let's keep in touch!</h4>
              <h5 className="text-lg mt-0 mb-2 text-slate-600 dark:text-slate-400">
                Find me on any of these social platforms, <br />I respond quickly!
              </h5>
              <div className="mt-6 lg:mb-0 mb-6">
                <a href="http://www.facebook.com/steveryherd">
                  <button
                    className="bg-white dark:bg-slate-200 hover:bg-blue-800 hover:text-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </button>
                </a>
                <a href="http://www.instagram.com/steveryherd">
                  <button
                    className="bg-white dark:bg-slate-200 hover:bg-pink-400 hover:text-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-instagram"></i>
                  </button>
                </a>
                <a href="https://www.instagram.com/steveryherdguestbook">
                  <button
                      className="bg-white dark:bg-slate-200 hover:bg-blue-500 hover:text-white text-blue-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                      type="button"
                  >
                    <i className="fab fa-instagram"></i>
                  </button>
                </a>
                <a href="https://github.com/steveryherd">
                  <button
                    className="bg-white dark:bg-slate-200 hover:bg-[#6e5494] hover:border-white hover:text-white text-slate-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-github"></i>
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full lg:w-7/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full md:w-1/3 px-4 ml-auto">
                  <span className="block font-accent font-extrabold uppercase text-teal-800 dark:text-[#e26742] text-sm mb-2">
                    Useful Links
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="footer-link"
                        href="http://card.steveryherd.com/"
                      >
                        Business Card
                      </a>
                    </li>
                    <li>
                      <a
                        className="footer-link"
                        href="calendar"
                      >
                        Schedule Appointment
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full md:w-1/3 px-4">
                  <span className="block font-accent font-extrabold uppercase text-teal-800 dark:text-[#e26742] text-sm mb-2 mt-6 md:mt-0">
                    Airport Toyota
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                          className="footer-link"
                          href="https://www.josephairporttoyota.com/new-vehicles/?ref=steveryherd"
                      >
                        New Inventory
                      </a>
                    </li>
                    <li>
                      <a
                          className="footer-link"
                          href="https://www.josephairporttoyota.com/used-vehicles/?ref=steveryherd"
                      >
                        Used Inventory
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full md:w-1/3 px-4">
                  <span className="block font-accent font-extrabold uppercase text-teal-800 dark:text-[#e26742] text-sm font-semibold mb-2 mt-6 md:mt-0">
                    Other Resources
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="footer-link"
                        href="https://www.josephairporttoyota.com/finance/apply-for-financing/?ref=steveryherd"
                      >
                        Apply for Financing
                      </a>
                    </li>
                    <li>
                      <a
                        className="footer-link"
                        href="https://www.josephairporttoyota.com/payment-calculator/"
                      >
                        Payment Calculator
                      </a>
                    </li>
                    <li>
                      <a
                        className="footer-link"
                        href="https://www.edmunds.com/appraisal/"
                      >
                        Value your Trade
                      </a>
                    </li>
                    <li>
                      <a
                        className="footer-link"
                        href="https://www.toyota.com/local-specials/"
                      >
                        Local Specials
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="group mt-6">
            <hr className="border-slate-300 dark:border-slate-500 group-hover:rainbow" />
            <div className="flex items-center justify-center h-20 -mt-10">
              <a href="https://www.opentoall.com/about/">
                <img className="dark:bg-slate-200 bg-white border-slate-300 dark:border-slate-500 group-hover:border-[#760088] border-2 rounded-full px-8 py-2 select-none" width="150" src={open_to_all} alt="Open to All" />
              </a>
            </div>
          </div>


          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">

              {/* <div className="-mt-7 mb-2 py-1 rounded-full bg-white dark:bg-slate-200">
                <a href="https://www.opentoall.com/about/">
                  <img className="mx-auto px-4 select-none" width="150" src={open_to_all} alt="Open to All" />
                </a>
              </div> */}

              <div className="text-sm text-slate-500 font-thin py-1">
                Copyright © {new Date().getFullYear()} {" "}
                <a
                  href="https://www.steveryherd.com?ref=footer"
                  className="text-slate-500 font-accent font-light hover:text-slate-800 dark:hover:text-slate-100"
                >
                  Steve Ryherd
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
