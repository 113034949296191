import React, { useEffect } from "react";

import Navbar from "components/Navbars/AuthNavbar.js";
// import Footer from "components/Footers/Footer.js";

import Cal, { getCalApi } from "@calcom/embed-react";

export default function Calendar() {

  useEffect(()=>{
	  (async function () {
      const cal = await getCalApi();
      cal("ui", {"styles":{"branding":{"brandColor":"#98d1fa"}},"hideEventTypeDetails":false,"layout":"week_view"});
	  })();
	}, [])

  return (
    <>
      <Navbar />
      <div className="clear-both" style={{paddingTop:120+'px', background: '#000000'}}>
        <Cal
          calLink="steveryherd"
          style={{width:"100%",height:"100%",overflow:"scroll"}}
          config={{layout: 'week_view'}}
        />
      </div>

    </>
  );
}
