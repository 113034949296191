// import React from "react";
import React, {useEffect, useState} from 'react';

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

// import { app } from 'firebaseConfig.js'; // Import your Firebase configuration
// import { getFirestore, collection, getDocs } from 'firebase/firestore';


const Testimonial = ({ heading, children, clientName, vehicle }) => {
  return (
      <div className="flex mt-8 py-4 justify-center">
        <div className="testimonial w-full lg:w-6/12">
          <h1 className="text-xl mb-2">{heading}</h1>
            {children}
          <div>
            <b>{clientName}</b>{vehicle && <> - {vehicle}</>}
          </div>
        </div>
      </div>
  );
};

const useFetchTestimonials = (setTestimonials) => {
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch('testimonials.json');
        const data = await response.json();
        setTestimonials(data);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      }
    };

    fetchTestimonials();
  }, [setTestimonials]); // Include dependencies if needed
};


export default function Profile() {

  const [testimonials, setTestimonials] = useState([]);
  useFetchTestimonials(setTestimonials);

  return (
    <>
      <Navbar transparent />

      {/* <main className="profile-page z-0 -m-[8rem] md:-m-20 lg:m-0"> */}
      <main className="profile-page z-0 mt-[325px]">
        {/* <section className="relatives block h-[350px]">
          <div
            className="absolute top-0 w-full h-full bg-cover bg-zinc-950"
            style={{
              background: '#000000',
              backgroundImage: 
              'url("https://images.unsplash.com/photo-1495435229349-e86db7bfa013?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3354&q=80")',
              backgroundPosition: 'center',  // Add this line for positioning if needed
              backgroundRepeat: 'no-repeat', // Add this line for repetition if needed
              backgroundSize: 'cover',  
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-30 bg-black"
            ></span>
          </div>
        </section> */}
        <section className="relative py-16">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white dark:bg-black dark:text-white w-full mb-6 shadow-xl rounded-lg -mt-[24rem] sm:-mt-[22rem] md:-mt-[20rem] lg:-mt-64">


              <div className="px-6">
                <div className="text-center mt-5 lg:mt-12">
                  <div className="md:my-10 lg:my-12">
                    <h3 className="text-4xl font-semibold leading-normal">
                      Client Testimonials
                    </h3>
                    {/* <h4>
                      <span>Your <span class="border-b-orange-200 border-b-2 font-bold">trusted</span> sales and leasing consultant</span>
                    </h4> */}
                  </div>
                </div>

                {testimonials.map(testimonial => (
                  <Testimonial
                    key={testimonial.id} // Assuming there's an "id" in your Firebase document
                    heading={testimonial.heading}
                    clientName={testimonial.clientName}
                    vehicle={testimonial.vehicle}
                  >
                    <p>{testimonial.content}</p>
                  </Testimonial>
                ))}

                <Testimonial 
                  heading="Greetings!"
                  clientName="Dave H."
                  vehicle="Toyota Rav4"
                >
                  <p>Allow this humble new car owner to introduce you to Mr. Steve Ryherd.</p>
                  <p>I discovered there is a difference in someone wanting to sell you a car versus 
                    that indvidual whose primary interest is in helping you purchase an automobile.  
                    I invite you to discover that difference.   You will find that Steve really listens, 
                    cares, respects your humanity and will treat you with uncommon courtesy.   A rare find!
                  </p>
                  <p>
                    There was no question that a Toyota car was right for me.   I'm so glad I found Steve 
                    and this dealership.  All three are tops in their field.  Thank you, Steve!
                  </p>
                </Testimonial>


                <Testimonial 
                  heading="Dear Steve,"
                  clientName="Lonn + Nancy J. (Jackson)"
                  vehicle="Toyota Camry"
                >
                    <p>Thank you so much for helping us with the purchase of our new 2018 Toyota Camry, we love the car!  We also loved your card tricks + your 
                    pool videos"!  They helped ease us waiting on a long night.   [<abbr title="We hope you enjoy this restaurant - it is one of our favorites,">...</abbr>] thanks again -- you were terrific!</p>
                </Testimonial>


                <Testimonial 
                  heading="Dear Steve,"
                  clientName="Becky J. (Johnson)"
                  vehicle="Toyota Corolla IM"
                >
                    <p>Just wanted to drop you a note to say "thanks" for making my new car purchase more enjoyable.   I had been pondering for a year to make a purcharse and you made it easy.   As you know, I worked in financial at Wylers for 12 years and I can honestly say, I have never had the opportunity to see a sales rep as professional and courteous as you.   You were not pushy and allowed me the necessary time I need to look at pros and cons of each model.   Dealerships could use a lot more of you.   Enjoy a lunch or dinner on me.</p>
                </Testimonial>


                <Testimonial 
                  heading="Steve,"
                  clientName="Don + Connie H. (Hild)"
                >
                    <p>Just a little note with <span className="bold underline">huge</span> <span className="bold underline">gratitude</span><span className="bold">!</span></p>
                    <p>Thank you, <span className="underline">so</span> <span className="underline">much</span>, for all your help today.</p>
                </Testimonial>


              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
